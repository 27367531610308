import styled from 'styled-components';

export const Container = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background: none;

  ${({ layerId, editing, disabled }) => {
    if (editing) {
      return `
        border: 1px dashed green;

        &:before {
            content: "${layerId} \\000BB";
            position:absolute;
            top: -24px;
            right: -54px;
            background: green;
            color: white;
            font-size: 12px;
            padding: 5px 10px 3px;
            font-weight: bold;
            border-radius: 10px 10px 10px 0;
        }`;
    }
    if (disabled) {
      return `
            opacity: 0.5;
        `;
    }
  }}
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;
